.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.navbar-custom {
  font-size: large;
  background-color: #ffffff00;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between; /* Space out navbar content */
  align-items: center;
}

.transparent-navbar {
  transition: background-color 0.3s ease;
}

.transparent-navbar.light-mode {
  background-color: #000000;
}

.transparent-navbar.light-mode .navbar__link {
  color: black !important;
}

.transparent-navbar.dark-mode .navbar__link {
  color: white !important;
}

.transparent-navbar.dark-mode {
  background-color: rgba(0, 0, 0, 0.5);
}

.transparent-navbar.dark-mode .navbar__link:hover {
  color: lightgray;
}

.navbar__link {
  margin-left: 0.75em;
  margin-right: 0.75em;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  border-bottom: 3px solid transparent;
  position: relative;
}

.navbar__link::after {
  transition: all ease-in-out 0.2s;
  content: "";
  display: block;
  margin-top: 2px;
  height: 3px;
  width: 0;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}

.navbar__link:hover::after {
  visibility: visible;
  width: 40%;
}

.navbar__link--active::after {
  transition: all ease-in-out 0.2s;
  width: 100%;
}

.navbar__link--active:hover::after {
  width: 100%;
}

.MainApp {
  margin-top: 8vh;
  height: calc(100vh - 8vh); /* Adjust dynamically */
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.Typewriter {
  display: inline-block;
}

.Typewriter__wrapper,
.Typewriter__cursor {
  font-size: xx-large;
  font-weight: bold;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: 500;
}

.social {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .social {
    margin-top: 30px; /* Reduce margin for small screens */
  }
}

@media (max-width: 768px) {
  .navbar__links {
    display: none; /* Hide the links */
  }

  .navbar__menu-button {
    display: block; /* Show the hamburger menu button */
  }
}

@media (max-width: 480px) {
  .navbar__link {
    margin-left: 0.02em;
    margin-right: 0.02em;
    font-size: 0.8em;
    padding: 0.025em;
    flex: 1 1 100%; /* Stack links vertically */
    text-align: center;
  }
}

.header {
  font-size: 3em;
  font-weight: bold;
  text-align: left;
  position: relative;
  margin: 2em 0 2em 1.5em; /* Use relative units */
  font-family: 'Baguette Script', cursive;
}

@media (max-width: 768px) {
  .header {
    font-size: 2em; /* Reduce font size for small screens */
    margin: 1em 0 1em 1em; /* Adjust margins for small screens */
  }
}

/* Navbar styles */
.navbar-custom {
  padding: 0.5em 1em;
}

/* Hamburger menu button */
.navbar-toggler {
  border: none;
  outline: none;
}

/* Collapsed menu */
.navbar-collapse {
  background-color: #ffffff00;
  padding: 1em;
}


/* Navbar links */
.navbar__link {
  margin: 0.5em 0; /* Add spacing between stacked links */
  text-align: center; /* Center-align links */
}

/* Media query for small screens */
@media (max-width: 768px) {
  .navbar-custom {
    padding: 0.5em; /* Reduce padding for small screens */
  }

  .navbar-collapse {
    position: absolute;
    top: 60px; /* Adjust based on navbar height */
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it appears above other content */
  }
}

/* Logo styles */
.navbar-brand img {
  width: 250px;
  height: 36px;
}

@media (max-width: 768px) {
  .navbar-brand img {
    width: 220px; /* Reduce logo size for small screens */
    height: 32px;
  }
}

/* Theme toggler styles */
.theme-toggler {
  margin-left: auto; /* Push toggler to the right */
}

/* Navbar styles for larger screens */
.navbar-custom {
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between; /* Space out navbar content */
  align-items: center;
}

/* Navbar links */
.navbar-nav {
  display: flex;
  gap: 1em; /* Add spacing between links */
}

/* Theme toggler */
.theme-toggler {
  margin-left: auto; /* Push toggler to the right */
}

/* Navbar container */
.navbar-custom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Navbar brand (logo) */
.navbar-brand {
  margin-right: 2em; /* Add spacing between logo and links */
}

/* Navbar links */
.navbar-nav {
  display: flex;
  gap: 1em; /* Add spacing between links */
}

/* Theme toggler */
.theme-toggler {
  margin-left: 2em; /* Add spacing between links and toggler */
}

/* Collapsed menu styles (only for small screens) */
@media (max-width: 768px) {
  .navbar-collapse {
    /* background-color: #ffffff00; Match navbar background */
    background-color: rgba(245, 245, 245, 0.98);
    padding: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for visibility */
    position: absolute;
    top: 60px; /* Adjust based on navbar height */
    left: 0;
    width: 100%;
    z-index: 10000; /* Ensure it appears above other content */
  }
}